@import "../../styles/variables.less";

.footer {
  padding: 24px 0;
  margin-top: 40px;
  background-color: #fff;
  font-size: 1.14rem;
  // position: absolute;
  // z-index: 1;
  // left: 0;
  // right: 0;
  // bottom: 0;
  margin-top: auto;
  .link,
  .bottom_link {
    &:hover {
      color: @orange;
    }
  }
  .link {
    font-size: 1.14rem;
    font-weight: 500;
  }
  .bottom_link {
    text-decoration: underline;
    font-size: 15px;
    &:hover {
      text-decoration: none;
    }
  }

  // @media only screen and (max-width: 1658px) {
  //     .wrap {
  //         flex: 0 0 80%;
  //         width: 100%;
  //         max-width: 80%;
  //     }
  // }

  @media only screen and (max-width: 1230px) {
    .wrap {
      flex: 0 0 90%;
      width: 100%;
      max-width: 90%;
    }
  }
}
