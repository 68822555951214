h1,h2,h3,h4,h5{
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 2.28rem;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 1.2;
}
h2 {
    font-size: 1.71rem;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 1.3;
}


h5{
    font-size: 1.28rem;
    font-weight: 500;
}