@import "Styles/variables.less";

.wrapper{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 50px;

    .radio_btns{
        display: flex;
        align-items: center;
        justify-content: center;

        :global{
            .ant-radio-wrapper{
                span{
                    margin-top: -2px;
                }
                .ant-radio{
                    margin-bottom: -2px;
                }
            }
        }
    }

    .search_content{
        padding: 32px;

        display: flex;
        justify-content: space-between;

        &__categories{
            max-width:  23%;
            word-wrap: break-word;

            :global{
                .ant-pagination{
                    width: 400px;
                }
            }
        }

        &__products{
            flex: 0 1 72%;
        }

    }

    @media (max-width:1100px) {
        padding-top: 50px;
    }
}

.goBack{
    max-width: 500px;
    display: block;
    padding: 32px;
    padding-bottom: 0px;
    color: @orange;

    .arrow{
        transform: rotate(-90deg);
        margin-right: 10px;
    }
}

.not_visible {
    opacity: 0;
}
.visible {
    opacity: 1;
    transition: .3s;
}