@import "Styles/variables";

.sider {
  border-top: @accountBorder;
  border-right: @accountBorder;

  :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background: @grey6;
    width: 100%;
  }
}

.userType {
  display: flex;
  height: 45px;
  border-bottom: @accountBorder;
}

.btn {
  &:global(.ant-btn) {
    height: 100%;
    width: 151px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    color: @fontColor;
  }
}

.activeBtn {
  composes: btn;

  &:global(.ant-btn) {
    width: 152px;
    color: #fff;
    background: @blackBlue;

    &:hover,
    &:focus {
      background: @blackBlue;
      color: #fff;
    }
  }
}

.menu {
  &:global(.ant-menu) {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: @general;
  }

  &:global(.ant-menu-light .ant-menu-submenu-title:hover) {
    color: @general;
  }

  &:global(.ant-menu-inline) {
    border-right: none;
  }

  &:global(.ant-menu-light .ant-menu-submenu-active) {
    color: @general;
  }

  &:global(.ant-menu-inline > .ant-menu-item) {
    height: auto;
  }

  :global {
    .ant-menu-submenu-selected {
      color: @general;
    }

    .ant-menu-item-selected {
      color: @general;

      &:after {
        display: none;
      }
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover,
    .ant-menu-item a:hover {
      color: @general;
    }

    .ant-menu-title-content {
      padding: 2px 0;
      line-height: 36px;
    }

    .ant-menu-item {
      white-space: normal;

      &:hover {
        background: @grey6;
      }
    }

    .ant-menu-sub.ant-menu-inline {
      background: transparent;

      & > .ant-menu-item {
        height: auto;
      }
    }
  }
}

.wrapperConnect {
  padding: 10px 10px 10px 24px;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
}

.connect {
  margin-bottom: 32px;
}

.connectLink {
  cursor: pointer;
  text-decoration: underline;
  color: #40a9ff;
}

.itemWrapper {
  margin-top: 100px;
  margin-left: 45px;
  width: 235px;
}

.item {
  word-break: break-word;
  margin-top: 5px;
  font-size: 14px;
  color: #474747;
  font-weight: 400;
}
