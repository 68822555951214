.section {
  composes: section from "Components/Dialogs/DialogsTable.module.less";

  :global {
    .ant-table-wrapper {
      margin-top: 0px !important;
    }
  }
}

.link {
  composes: link from "Components/Dialogs/DialogsTable.module.less";
  flex-grow: initial;
  //max-width: 176px;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.disabledLink {
  composes: link;
  color: #ccc;
  pointer-events: none;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  a{
    max-width: 190px;
  }
}

.productName {
  justify-content: flex-start !important;
}

:global(.ant-pagination.ant-table-pagination) {
  font-size: 16px;
  font-weight: 600;
}
:global(.ant-pagination .ant-pagination-item) {
  width: 42px;
}

:global(.ant-pagination .ant-pagination-item-active) {
  border-top-width: 2px;
  &:hover {
    border-top-color: #ea5b0c;
  }
}
:global(.ant-pagination.ant-table-pagination .ant-pagination-item:hover a),
:global(.ant-pagination.ant-table-pagination .ant-pagination-prev:hover a),
:global(.ant-pagination.ant-table-pagination .ant-pagination-next:hover a) {
  color: #343543;
}

.qmodal {
  :global {
    .ant-modal-header {
      border-bottom: 0px !important;
      padding-bottom: 0px;
      padding-top: 20px;
    }
    .ant-modal-body {
      padding-top: 0;
    }
    .ant-modal-footer {
      padding-top: 0;
      padding-bottom: 20px;
      display: flex;
      gap: 90px;
    }

    .btn {
      font-size: 16px !important;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      height: auto !important;
      margin: 0 !important;
    }

    .btn_v8 {
      font-weight: 600 !important;
    }
  }

  .qmodal_text {
    margin-top: 27px !important;
    color: #474747 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.45px !important;
    font-weight: 400 !important;

    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    span {
      display: block;
      margin-top: 8px;
    }
  }
}
