@import "Styles/variables";
.badge {
  position: absolute !important;
  top: -5px;
  right: -5px;
  :global {
    .ant-badge-count {
      background: #ea5b0c;
    }
  }
}

.section {
  max-width: 960px;

  :global {
    label {
      width: 320px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-weight: 600;
      color: @fontColor;
    }
  }
}

.filters {
  display: flex;
  height: 48px;
  margin-bottom: 32px;
  background: @grey4;
}

.controls {
  display: flex;
  gap: 24px;
}

.btn {
  &:global(.ant-btn) {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #fff;
    height: 44px;
  }
}

.showNew {
  &:global(.ant-btn) {
    padding: 0 24px;
  }
}

.show {
  composes: btn;

  &:global(.ant-btn) {
    padding: 0 40px;
    background: @orange;

    &:hover,
    &:focus {
      background: @orange;
    }
  }
}

.reset {
  &:global(.ant-btn) {
    height: 44px;
    padding: 0 26px;
    margin-left: auto;
    font-weight: 300;
    font-size: 16px;
    color: @general;
  }
}
