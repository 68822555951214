@import "Styles/variables";

.section {
  max-width: 1380px;
  padding: 32px 48px 77px 32px;
  background: #fff;
  font-weight: 500;

  input,
  textarea {
    background: @grey4;
    font-size: 16px;
  }

  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: @grey4;
    }

    .ant-input:hover {
      border-color: #b0aeb4;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #b0aeb4;
    }

    .ant-form-item-label > label {
      height: 40px;
    }
  }

  :global(.ant-select-selection-placeholder) {
    font-size: 14px;
    color: @grey;
  }

  :global(.ant-form-item-explain) {
    font-weight: 400;
  }
}

.captcha {
  border: 1px solid #e8edf0;

  div {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.link {
  white-space: nowrap;
  &_v2 {
    text-decoration: underline !important;
    color: black !important;
    &:hover {
      color: @orange !important;
    }
  }
  &_v3 {
    text-decoration: none !important;
    color: @orange !important;
  }
}

.label {
  width: 140px;
  white-space: normal;
  line-height: 16px;
}

.submit {
  &:global(.ant-btn) {
    background: @orange;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
    border: none;

    &:hover {
      background: @blackBlue;
      color: #fff;
    }

    &:focus {
      background: @orange;
      color: #fff;
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 60px 50px 25px;
    }

    .ant-modal-header {
      border-bottom: none;
    }

    .ant-modal-title {
      font-size: 20px;
    }

    .ant-modal-body {
      padding: 10px;
    }
  }
}

.controlBtn {
  &:global(.ant-btn) {
    padding: 0 40px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.35px;
    font-weight: 300;
    background: @blackBlue;

    &:hover,
    &:focus {
      background: @blackBlue;
      border-color: @blackBlue;
      color: #fff;
    }
  }
}

.TextArea {
  background-color: #f5f7f8;
  padding: 20px;
  min-height: 100px !important;
  textarea {
    min-height: 100px;
    font-size: 16px;
  }
}
