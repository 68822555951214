.container {
    background-color: white;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
}

.arrow {
    transform: rotate(30deg);
    margin-bottom: 4px;
}

.button {
    display: flex;
    align-items: center;
    gap: 12px;
    &:hover {
        cursor: pointer;
    }
}

.goBackText {
    text-decoration: underline;
}