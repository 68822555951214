@import "Styles/variables";
.label {
  line-height: 1.2;
  font-weight: 500;

  &.req::after {
    content: "*";
  }
}

.modal {
  :global {
    .ant-modal-footer {
      margin-bottom: 23px;
    }
  }
}

.section {
  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: @grey4;
    }

    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }
}

.sectionOver {
  composes: section from "Components/Dialogs/DialogsTable.module.less";
  overflow-x: auto;
  padding-bottom: 5px;
  width: 100%;
}
.passwordInput {
  :global {
    input.ant-input {
      background-color: rgb(245, 247, 248);
    }
  }
}
