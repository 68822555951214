.checkbox{
  height: 17px;
  width: 17px;
  border: 2px solid #FF7326;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .flag{
transition: 0.3s;
    transform: scale(0);
    &.checked{
      transform: scale(1);
    }
  }
}
