@import "Styles/variables";

.section {
  background: #fff;
  padding: 32px;

  :global {
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background: @grey4;
    }

    .ant-input:hover {
      border-color: #b0aeb4;
    }

    .ant-form-item {
      align-items: baseline;
    }

    .ant-checkbox-wrapper {
      align-items: center;
      gap: 7px;
    }

    .ant-form-item-explain-error {
      max-width: fit-content;
    }
  }
}

.scrollable {
  overflow-x: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.submit {
  composes: submit from "Pages/FS21-LeaveRequestBuyer/LeaveRequestBuyer.module.less";
}

.modal {
  :global {
    .ant-modal-content {
      padding: 60px 50px 25px;
    }

    .ant-modal-title {
      font-size: 20px;
    }

    .ant-modal-body {
      padding: 16px;
    }
  }
}

.controlBtn {
  &:global(.ant-btn) {
    padding: 0 40px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.35px;
    font-weight: 300;
    background: @orange;

    &:hover,
    &:focus {
      background: @blackBlue;
      border-color: @blackBlue;
      color: #fff;
    }
  }
}
