.whyWithUs {
  margin: auto;
  margin-top: 50px;
  width: 900px;
  height: auto;
  border: 4px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 30px;

  .list {
    width: 91.2%;
    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
      li {
        font-size: 17px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 6px;
        &::marker {
          color: #ea5b0c;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}
.whatYouDo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .whatYouDo_title {
    width: 270px;
    height: 50px;
    border: 2px solid #ea5b0c;
    font-size: 20px;
    line-height: 21.09px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .applicationFill {
    width: 385px;
    height: auto;
    border: 2px dashed black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
}

.whatWeDo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .whatWeDo_title {
    width: 270px;
    height: 50px;
    border: 2px solid #ea5b0c;
    font-size: 20px;
    line-height: 21.09px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .whatWeDoBlock {
    width: 500px;
    height: auto;
    border: 2px solid #ea5b0c;
    padding: 20px;

    p {
      font-size: 15px;
      font-weight: 300;
      line-height: 16.41px;
    }
  }
}

.result {
  margin: auto;
  width: 720px;
  height: auto;
  background: #343543;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .result_text {
    padding: 20px 0;
    width: 55%;
    h3 {
      text-align: center;
      color: white;
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.onlineReasons {
  width: 946px;
  margin: auto;

  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 97%;
    li {
      font-size: 17px;
      font-weight: 500;
      color: #222222;
      margin-bottom: 6px;
      &::marker {
        color: #ea5b0c;
        width: 6px;
        height: 6px;
      }
    }
  }
}

.buyersSearch {
  width: 900px;
  margin: auto;

  p {
    font-size: 14px;
  }
}

.captcha {
  border: 1px solid #e8edf0;

  div {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.link {
  text-align: left;
}

.label {
  line-height: 1.2;
  font-weight: 500;

  &.req::after {
    content: "*";
  }
}

.emailConfirm {
  width: 930px;
  height: auto;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 40px;

  h3 {
    font-size: 18px;
  }

  .instruction {
    width: 76%;
    p {
      font-size: 14px;
      font-weight: 400;
    }

    ul {
      li {
        font-size: 15px;
        margin: 7px 0;
      }
    }
  }

  .support {
    margin-top: 40px;
  }
}

.textarea {
  min-height: 120px !important;
}

.wrapper {
  :global {
    .ant-col-7 {
      max-width: 56%;
    }
  }
}

.message {
  :global {
    .ant-col-40 {
      max-width: 870px !important;
      width: 870px !important;
    }
  }
}

.global_wrapper {
  :global {
    .ant-row-center {
      .ant-col {
        max-width: 96.666667%;
      }
    }
  }
}

.how_we_work {
  display: flex;
  justify-content: center;
  :global {
    .ant-row {
      grid-gap: 35px;
      flex-flow: nowrap;
    }
  }
}

@media screen {
  @media (min-width: 1224px) {
    .whatYouDo {
      margin-left: 37px;
    }
  }
}
