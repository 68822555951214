@import "Styles/variables";

.roles_selector {
  :global {
    .ant-select-selector {
      background-color: #f5f7f8 !important;
      border-radius: 0 !important;
      max-height: 32px !important;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  a {
    .excel {
      width: 266px;
      height: 44px;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      height: auto;
      border: 1px solid @blackBlue;
      cursor: pointer;
      transition: all 0.2s linear;
    }
  }
  .addUser {
    width: 266px;
    height: 44px;
    border: none;
    background: @orange;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
    width: max-content;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
    &:focus {
      color: #fff;
      background: @orange;
    }
  }
}

.label {
  line-height: 1.2;
  font-weight: 500;

  &.req::after {
    content: "*";
  }
}

.adduserModal {
  :global {
    .ant-form-item-required {
      .ant-form-item-label > label::after {
        content: none;
      }
    }
  }
}

.passwordInput {
  :global {
    input.ant-input {
      background-color: rgb(245, 247, 248);
    }
  }
}

.count {
  font-size: 16px;
  font-weight: 400;
  .amount {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: @orange;
    }
  }
}

.modal {
  width: 716px !important;
}
