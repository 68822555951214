@import "./variables";

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  font-size: 1rem;
  background-color: @grey4;
  position: relative;
  z-index: 1;
  color: @general;
  letter-spacing: 0.35px;

  #app {
    min-height: 100vh;
    min-width: 992px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
}

p {
  margin: 0;
  padding: 0;
}

.w-100 {
  width: 100%;
}

.loginError {
  color: @red;
  background-color: @rose;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
}

.border-red {
  border: 1px solid @red;
}

.d-flex {
  display: flex;
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.border-grey {
  border: 1px solid @grey2;
  padding: 16px 16px 24px 16px;
}

.d-none {
  display: none !important;
}

.underline {
  text-decoration: underline;
}

.label_v2 {
  font-size: 0.86rem;
  padding: 12px 16px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: max-content;

  &.label_v2_green {
    background: @lightGreen;
    color: @green;
  }

  &.label_v2_red {
    background: @lightRed;
    color: @darkRose;
  }
  &.label_v2_orange {
    background: #fbe9dc;
    color: #d67e2d;
  }
}

.divider {
  height: 24px;
  width: 2px;
  background: @grey2;
}
.actions_block {
  display: flex;
  align-items: center;



  & > *:first-child {
    margin-right: 12px;
  }

  & > *:not(:first-child):not(:last-child) {
    margin-left: 12px;
    margin-right: 12px;
  }

  & > *:last-child {
    margin-left: 12px;
  }
}

.word-break {
  word-break: break-all;
}

.modalStyle {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 304px;
  right: 0;
  bottom: 0;
  padding: 40px;
  background: rgba(0, 0, 0, 0.1);
  overflow: auto;
  .modalStyleContent {
    position: absolute;
    top: 32px;
    left: calc(32px);
    right: 0;
    //bottom: 0;
  }
}

.maxHeight430 {
  max-height: 430px;
  overflow: auto;
}

.customScroll {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: @grey4;
  }
  &::-webkit-scrollbar-thumb {
    background: @grey2;
    border-radius: 8px;
  }
}
iframe {
  width: 390px;
  //height: 260px;
}
html {
  scroll-behavior: smooth;
}

.country_flag {
  display: inline-block;
  width: 26px;
  height: 18px;
  vertical-align: middle;
  border-radius: 5px;
  margin-top: -5px;
  margin-left: 8px;
}
.info_table {
  font-size: 12px;
  color: rgba(#343543, 0.6);
  border: 1px solid #e8edf0;
  p {
    padding: 8px;
    line-height: 1;
    display: flex;
    justify-content: space-between;
  }
  p:not(:last-child) {
    border-bottom: 1px solid #e8edf0;
  }
}

.table_action {
  display: flex;
  align-items: center;
  gap: 10px;

  span{
    font-size: 10px;
    padding: 2px 5px;
    line-height: 14px;
    border-radius: 50%;
    background: #EA5B0C;
    color: #fff;
    font-weight: 500;
  }
}
