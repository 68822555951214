@import './variables';
a{
    color: @blackBlue;
}
a.blue{
    color: #478ECC;
}
a.orange{
    color: @orange;
}
