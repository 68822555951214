@import "Styles/variables";

.section {
  min-width: max-content;

  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: @grey4;
    }

    tr.selected.ant-table-row,
    tr.selected.ant-table-row td.ant-table-column-sort {
      background: #f5f5f5;
    }

    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }
}

.toDialog {
  display: inline-flex;
  width: max-content;
  align-items: center;
  gap: 10px;
  background: @grey4;
  text-decoration: underline;
  height: 40px;
  border-radius: 100px;
  padding: 16px;
  color: @orange;
}

.link {
  text-decoration: underline;
  color: @orange;
}
