@import "../../styles/variables";

.wrapper{
  background: #fff;
  min-height: 100%;
  padding: 32px;
  overflow-x: hidden !important;
  max-width: 90vw;
  .excel_btn{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: auto;
    border: 1px solid @blackBlue;
    cursor: pointer;
    width: max-content;
  }

  .requests_table__container{
    width: 100% !important;
  }

  .requests_table{
    margin-top: 35px;
    :global(.ant-table-tbody > tr.ant-table-row > .ant-table-cell:first-child) {
      padding: 16px 16px;
    }
    :global(.ant-table-tbody > tr > td) {
      padding: 0;
    }
    span {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 16px;
    }
    :global{
      .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-thead > tr > .ant-table-cell{
        border-bottom: none;
        background: #F5F7F8;
        min-width: 120px !important;
        min-height: 60px !important;
        max-width: 300px !important;
        height: 60px !important;
        padding-right: 0;
        &:nth-child(2){
          max-width: 150px !important;

        }
      }

      .ant-table-tbody > tr.ant-table-row > .ant-table-cell{
        &:first-child{
          background: #F5F7F8 !important;
          min-height: 60px !important;
          height: 60px !important;
          font-weight: 600;
          color: #474747;
          text-decoration: none;
          cursor: text;
          &:hover{
            color: #474747 !important;
          }
        }

        cursor: pointer;

        span{
          display: block;
          text-decoration: underline;
        }

        &:hover{
          color: @orange !important;
        }
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #fff !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > .ant-table-row:last-child > td.ant-table-cell{
        border-bottom: none!important;
      }
    }
  }

  .filter_table_container{
    width: calc(100%);
    overflow-x: auto;
    margin-top: 20px;
    // margin-left: -32px;
  }

  .red{
    color: red !important;
  }

  .filter_table_search{
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .contact_link{
    color: @orange;
    text-decoration: underline;
    display: block;
    margin-top: 10px;
    padding: 0px;
    text-align: left;
    background: transparent;
    border: none;
    cursor: pointer !important;
  }

  .w210{
    width: 210px !important;
    max-width: 210px !important;
    padding-right: 0px !important;
  }

  @media (max-width: 1800px) {
    max-width: calc(100vw - 303px - 64px - 18px);
  }

  @media screen and (max-width: 1200px) {
    max-width: calc(100vw - 303px - 64px - 18px);
  }

  .changeStatusSelect{
    width: 210px;
    padding: 0 !important;

    :global{
      .ant-select-selector{
        background: transparent !important;
        padding: 0px !important;
        padding-right: 7px !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}