.wrapper {
  display: flex;

  a:hover {
    color: #EA5B0C !important;
  }
}

.link {
  padding: 10px 16px;
  font-size: 15px;
  font-weight: 600;
}

.active {
  color: #EA5B0C;
  padding: 10px 16px;
  border-bottom: 2px #EA5B0C solid;
  font-size: 15px;
  font-weight: 600;
}
.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.ant-anchor-ink:before {
  display: none !important;
}
.modal {
  :global(.ant-modal-content) {
    width: max-content;
  }

}
.customLink {
  color: #343543;
  transition: .3s;
  font-size: 15px;
  font-weight: 600;
  align-self: center;
  cursor: pointer;
  &:hover{
    color: #EA5B0C;
  }
}
