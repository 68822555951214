:global{
  .ant-select-selector {
    height: 41px;
  }
}
.captcha {
  border: 1px solid #e8edf0;

  div {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.link {
  text-align: left;
}

.emailConfirm {
  width: 930px;
  height: auto;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 40px;

  h3 {
    font-size: 18px;
  }

  .instruction {
    width: 76%;
    p {
      font-size: 14px;
      font-weight: 400;
    }

    ul {
      li {
        font-size: 15px;
        margin: 7px 0;
      }
    }
  }

  .support {
    margin-top: 40px;
  }
}

.TextArea {
  background-color: #F5F7F8; 
  padding: 20px;
  min-height: 100px !important;
  textarea {
    min-height: 100px;
  }
}