@import "Styles/variables";

.section {
  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: @grey4;
    }

    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }

  max-width: 100% !important;

  .section__table {
    max-width: 100%;
    width: calc(100%);
    overflow-x: auto;
    margin-bottom: 24px;

    :global {
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-thead > tr > .ant-table-cell {
        //border-bottom: none;
        background: #f5f7f8;
        min-width: 158px !important;
        min-height: 64px !important;
        height: 64px !important;
      }

      .ant-table-thead > tr > .ant-table-cell {
        //border-bottom: none;
        background: #f5f7f8;
        min-width: 160px !important;
        min-height: 64px !important;
        height: 64px !important;
      }

      .ant-table-tbody > .ant-table-row > .ant-table-cell {
        line-height: 16px !important;
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #fff !important;
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > .ant-table-row:last-child > td.ant-table-cell {
        //border-bottom: none !important;
      }
    }
  }
}

.link {
  composes: link from "Components/Dialogs/DialogsTable.module.less";
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
