@import "Styles/variables";

.mainDialogWindow{
  //scroll-behavior: smooth;
  position: relative;
  background: @grey4;
  overflow: auto;
  height: 526px;
  max-height: 526px;
  margin-top: 18px;
  padding: 24px;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: @grey4;
  }
  &::-webkit-scrollbar-thumb {
    background: @grey2;
    border-radius: 8px;
  }

}
.uploadButton {
  display: flex;
  align-items: center;
  border: none !important;
  min-width: 42px;
  background: none !important;
  box-shadow: none !important;
  min-height: 48px;
  svg {
    width: 32px;
    height: 32px;
    fill: @orange;
  }
}

.messageInput {
  border: none !important;
  background: none !important;

  &:focus {
   box-shadow: none !important;
  }
}

.customInput {
  background-color: @grey4;
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding-right: 7px;
}

.form {
  :global {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}