.link {
  color: #ea5b0c;
  font-size: 14px;
  margin: 6px 0;
  cursor: pointer;
  width: 200px;
  font-weight: 400;
  transition: .2s;
}
.link:hover {
  color: #000 !important;
}
