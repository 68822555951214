.emailModalStyle {
  left: 0 !important;
  width: 100% !important;
}

.wrapper {
  width: 716px;
  min-height: 690px;
  margin-top: 60px;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label {
  font-weight: 500;
}
