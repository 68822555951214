@import "Styles/variables";
.wrap {
  line-height: 1.2;
  margin-right: 24px;
  padding: 8px;
  color: @orange;
  font-size: 1.14rem;
  position: relative;
  z-index: 2;

  .chevron {
    transition: 0.5s;
    margin-left: 5px;
    &.opened {
      transform: rotate(-180deg);
    }
  }
  .current {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .locales {
    min-width: 145px;
    padding: 16px 4px 16px 12px;
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    border: 1px solid @grey2;
    transition: 0.5s;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    &.opened {
      visibility: visible;
      opacity: 1;
      top: 100%;
    }
    .locale {
      padding: 8px;
      color: @black;
      cursor: pointer;
      &:hover {
        background: @grey4;
      }
      &.active {
        background: @grey4;
        font-weight: 500;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
