@import "Styles/variables";

.section {
  max-width: 100%;
  overflow-x: auto;
  position: relative !important;
  z-index: 1 !important;
  :global {
    label {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 16px;
      font-weight: 600;
      color: @fontColor;
      :global {
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: #b0aeb4;
        }
      }
    }

    .ant-picker {
      background: @grey4;

      &:hover {
        border-color: #b0aeb4;
      }
    }

    .ant-select {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      .ant-select-selector {
        width: 100%;
      }
    }

    .ant-select-selector {
      align-items: flex-start;
      padding: 0px;
      overflow: hidden;
      min-height: 32px !important;
      height: 32px !important;
      background: @grey4 !important;
      padding-right: 12px !important;
      .ant-select-selection-overflow-item-suffix {
        display: none;
      }
    }
  }
}

.filters {
  composes: filters from "Components/Dialogs/DialogsSearch.module.less";
  height: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1 !important;

  label {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start !important;
    gap: 5px;
    select {
      display: block;
      flex-grow: 1;
    }
  }
}

.controls {
  composes: controls from "Components/Dialogs/DialogsSearch.module.less";
  align-items: center;

  .controls__select {
    display: inline-block !important;
    font-size: 16px !important;
    font-weight: 300;
    select {
      font-size: 16px !important;
      padding: 5px 10px;
      cursor: pointer;
      outline: none;
      margin: 0px 10px;
    }

    :global {
      .ant-select {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        width: 73px !important;
      }

      .ant-select-selector {
        height: auto !important;
        max-height: initial !important;
        min-height: initial !important;
      }

      .ant-select-selection-item {
        line-height: 38px !important;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.btn {
  composes: btn from "Components/Dialogs/DialogsSearch.module.less";
}

.show {
  composes: show from "Components/Dialogs/DialogsSearch.module.less";
}

.reset {
  border: 1px solid #d9d9d9 !important;
  &:global(.ant-btn) {
    height: 44px;
    padding: 0 26px;
    font-weight: 300;
    font-size: 16px;
    color: @general;
    margin-left: auto;

    &:focus {
      color: @general;
    }
    &:active,
    &:hover,
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
  }

  &:hover {
    border: 1px solid #343543 !important;
    color: #fff !important;
    background: #343543 !important;
  }
  // composes: reset from 'Components/Dialogs/DialogsSearch.module.less';
}

.only_pagesize {
  margin: 20px 0px;
}
