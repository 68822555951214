@import "Styles/variables";

.link {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: @general;
}

.active {
  composes: link;
  font-weight: 500;
}

.noWrap {
  white-space: nowrap;
}
