@import "./variables.less";
div:not(#zmmtg-root) {
}
.btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-color: inherit;
  }

  &.btn_v1 {
    border: 1px solid @grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.14rem;
    color: #222;
    line-height: 1.5;
    padding: 10px;
    min-width: 100px;
    min-height: 44px;

    &:hover {
      color: #fff;
      background-color: @orange;
      border-color: @orange;
    }
  }

  &.btn_v2 {
    background: @orange;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
    width: max-content;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
  }

  &.btn_v3 {
    background: @blackBlue;
    color: #fff;
    padding: 14px 40px;
    font-weight: 300;

    &:hover {
      background: transparent;
      color: @blackBlue;
    }
  }

  &.btn_v4 {
    background: @darkRose;
    color: #fff;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 1.14rem;

    &.fw-300 {
      font-weight: 300;
    }

    &:hover {
      background: transparent;
      color: @darkRose;
    }
  }

  &.btn_v5 {
    height: auto;
    padding: 10px;
    position: relative;
    font-weight: 300;
    color: @blue;
    background: @grey4;
    text-decoration: underline;
    max-width: 100%;
  }

  &.btn_v6 {
    border: 1px solid @orange;
    background-color: @orange;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.14rem;
    color: #fff;
    line-height: 1.5;
    padding: 10px;
    min-width: 100px;
    min-height: 44px;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
  }

  &.btn_v7 {
    border-color: transparent;
    text-decoration: underline;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    color: @orange;
    line-height: 1.5;
    padding: 10px;
    min-width: 100px;
    min-height: 44px;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
  }

  &.btn_v8 {
    border-color: @grey;
    color: @general;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    width: max-content;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
  }

  &.btn_v9 {
    box-shadow: none;
    border-color: transparent;
    text-decoration: underline;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    color: @orange;
    line-height: 1.5;
    padding: 0px;
    min-height: 44px;
  }
  &.btn_v10 {
    height: 44px;
    background: @blackBlue;
    color: #fff;
    padding: 14px 40px;
    font-weight: 300;
    &:hover {
      border-color: @blackBlue;
      background: transparent;
      color: @blackBlue;
    }
  }
  &.btn_v11 {
    border-color: @grey;
    background: none;
    color: @orange;
    padding: 20px;
    font-weight: 700;
    &:hover {
      border-color: @grey;
      background: @orange;
      color: #fff;
    }
  }
  &.btn_v12 {
    border: 1px solid @grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.14rem;
    color: #222;
    line-height: 1.5;
    padding: 10px;
    min-width: 100px;
    min-height: 44px;

    &:hover {
      color: #fff;
      background-color: @blackBlue;
      border-color: @blackBlue;
    }
  }
  &.excel_btn {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: auto;
    border: 1px solid @blackBlue;
    cursor: pointer;
  }
}

.logoutBtn {
  color: @blackBlue;
  font-weight: 700;

  &:hover {
    color: @blackBlue;
  }
}
