@import "../../styles/variables.less";

.processComplaint {
  margin-bottom: 50px;
  textArea {
    min-height: 120px!important;
  }
  .info {
    font-size: 17px;
    display: flex;
    align-items: center;
    gap: 15px;
    h3 {
      display: inline-block;
    }
    p {
      display: inline-block;
    }
    .process_select {
      :global {
        .ant-select {
          :global {
            .ant-select-selector {
              :global {
                .ant-select-selection-placeholder {
                  color: #249f5d;
                  font-weight: 500;
                  font-size: 18px;
                  background: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
    .not_process_select {
      :global {
        .ant-select {
          :global {
            .ant-select-selector {
              :global {
                .ant-select-selection-placeholder {
                  color: @darkRose;
                  font-weight: 500;
                  font-size: 18px;
                  background: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
    .viewed_select {
      :global {
        .ant-select {
          :global {
            .ant-select-selector {
              :global {
                .ant-select-selection-placeholder {
                  color: #d67e2d;
                  font-weight: 500;
                  font-size: 18px;
                  background: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .not_processed {
    color: @darkRose;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .processed {
    color: #249f5d;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;


    background: none;
    border: none;
  }
  .viewed {
    color: #d67e2d;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;

    background: none;
    border: none;
  }
  .complaintInfo {
    width: fit-content;
    padding: 7px 10px;
    height: fit-content;
    border: 1px solid #e8edf0;
    font-size: 18px;

    .complaintFrom {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .complaintInfo_btns {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
}

.historyWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  .history_item {
    width: auto;
    height: 64px;
    background: @grey4;
    padding: 10px 20px 10px 20px;

    span {
      font-size: 16px;
      font-weight: 300;
      color: @grey;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: @grey7;
    }
  }
}

.addMessage {
  p {
    font-size: 14px;
    font-weight: 500;
    color: @general;
    margin-bottom: 10px;
  }
}

.link{
  color: @orange;
}
