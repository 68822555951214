@import "Styles/variables";

.section {
  :global {
    label {
      font-weight: 600;
      color: @fontColor;
      font-size: 14px;
      line-height: 24px;

      :global {
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: #b0aeb4;
        }
      }
    }

    .ant-form-item-label > label {
      height: auto;
    }

    .ant-picker,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: @grey4;
      &:hover {
        border-color: #b0aeb4;
      }
    }

    .ant-select-selector {
      height: auto !important;
    }
  }
}

.filters {
  display: flex;
  align-items: flex-end;
  padding: 16px 0 16px 16px;
  //margin-bottom: 32px;
  background: @grey4;
}

.controls {
  display: flex;
  gap: 24px;
}

.btn {
  &:global(.ant-btn) {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #fff;
    height: 44px;
  }
}

.showNew {
  composes: btn;

  &:global(.ant-btn) {
    background: @blackBlue;
    padding: 0 24px;

    &:hover {
      background: @blackBlue;
      color: #fff;
      border-color: @blackBlue;
    }
  }
}

.show {
  composes: btn;
  border: 0px !important;
  &:global(.ant-btn) {
    padding: 0 40px;
    background: @orange !important;
    color: #fff !important;
    border-color: @orange !important;

    &:hover {
      background: @blackBlue !important;
      color: white !important;
    }

    &:active,
    &:hover,
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
  }
}

.reset {
  border: 1px solid #d9d9d9 !important;
  &:global(.ant-btn) {
    height: 44px;
    padding: 0 26px;
    font-weight: 300;
    font-size: 16px;
    color: @general;
    margin-left: auto;

    &:focus {
      color: @general;
    }
    &:active,
    &:hover,
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
  }

  &:hover {
    border: 1px solid #343543 !important;
    color: #fff !important;
    background: #343543 !important;
  }
}

.scrollable {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
}
