@import "./variables";

.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error .ant-checkbox-inner,
.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error input {
  border-color: #ff4d4f;
}

.ant-input.ant-input {
  box-shadow: none !important;
  border-color: #d9d9d9;
}

.ant-picker-focused {
  box-shadow: none !important;
  border-color: #d9d9d9;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border-color: #d9d9d9;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #b0aeb4;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-select-selector,
.ant-form-item-has-error .ant-picker-input input {
  border: 1px solid #ff4d4f;
}

.ant-select-selection-placeholder {
  font-weight: 400;
}

.ant-checkbox-wrapper-in-form-item.small .ant-checkbox-inner {
  height: 24px;
  width: 24px;

  &::after {
    left: 23%;
    width: 9px;
    height: 15px;
  }
}

.label-auto-height {
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0;
  }

  label {
    height: auto;
  }
}

.ant-modal-content {
  font-size: 1.28rem;
  font-weight: 600;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.ant-modal-wrap {
  .ant-modal-body {
    p {
      margin-top: 30px;
      font-size: 1.28rem;
      font-weight: 500;
      text-align: center;
    }
  }

  .ant-modal-footer {
    border-top: none;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-anchor-ink:before {
  display: none !important;
}

.ant-anchor-wrapper {
  display: flex !important;
  align-items: center;
}

.jvectormap-tip {
  z-index: 9999;
  font-size: 12px !important;
}

.ant-select-selector {
  background-color: #f5f7f8 !important;
}

.ant-radio-wrapper {
  .ant-radio {
    background: @grey4;
    border-radius: 50%;

    .ant-radio-input {
      &:focus + .ant-radio-inner {
        box-shadow: none;
        outline: none;
      }
    }

    .ant-radio-inner {
      height: 20px;
      width: 20px;
      background: @grey4;
      border-color: transparent;

      &:after {
        width: 11px;
        height: 11px;
        transform: scale(1);
        margin-top: -6px;
        margin-left: -5px;
      }
    }

    &.ant-radio-checked .ant-radio-inner {
      &::after {
        background-color: @blackBlue;
      }
    }
  }
}

.hiddenCheckbox {
  .ant-checkbox,
  .ant-form-item-control-input {
    display: none;
  }
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-tab {
  font-size: 1.14rem;
  font-weight: 700;
  color: @grey7;
}

.ant-tabs-tab:hover {
  color: @orange;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @orange;
}

.ant-tabs-ink-bar {
  background: @orange;
}

.ant-table-pagination.ant-pagination {
  margin: 24px 0 0;
}

.ant-pagination-item {
  border: none;

  a {
    color: @blackBlue;
  }
}

.ant-pagination-item-active {
  border-top: 1px solid @orange;

  a {
    color: @blackBlue;
  }

  &:hover {
    border-top: 1px solid @blackBlue;
  }
}

.z {
  .ant-select-item-option-content {
    white-space: normal;
    word-break: break-word;
  }
}
.ant-pagination {
  flex-direction: row;
}

.rc-virtual-list-scrollbar-show {
  display: block !important;
}

.ant-select-item-group {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}
