@import "Styles/variables.less";

.container {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f8;
  padding: 18px;
  position: relative;
}

.buttons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 13px;
}

.btn {
  min-width: 19px !important;
  background: none !important;
  border: none !important;
  padding: 0px !important;

  &:hover {
    path {
      stroke: @blue;
    }
  }
}

.saveBtn {
  &:hover {
    fill: @blue;
  }
}

.date {
  color: @grey;
}
.note {
  width: 100%;
  word-break: break-word;
}
