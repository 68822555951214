.fw-500{
    font-weight: 500;
}
.fw-300{
    font-weight: 300;
}

.flex_admin_title{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .admin_page_title{
        max-width: 412px;
    }

    section{}
}

.admin_page_title, .admin_page_title>span{
    font-weight: 500 !important;
    font-size: 20px;
    line-height: 25px;
}