.wrapperModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;

  &::after {
    content: "";
    background-color: #000000;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

.modal {
  width: 1308px;
  height: 100%;

  background-color: #fff;
  position: relative;
  display: flex;
  z-index: 3000;
}

.menu {
  min-width: 300px;
  max-width: 320px;
  height: 100%;
  border-right: 1px solid #E8EDF0;
  padding: 24px 10px 24px 24px;
  overflow: auto;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #222222;
}

.main {
  padding: 24px 0;
  width: 100%;
  overflow: auto;
}

.topLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 32px;
}
.hint {
  width: 40%;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
}
.btnWrapper {
  padding: 24px 10px;
  width: 100%;
}
.btn1, .btn2 {
  padding: 14px 40px;
  background-color: #EA5B0C;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    border-color: #343543;
    background: #343543;
    color: #fff ;
  }
}

.btn2 {
  border: 1px solid #B0AEB4;
  color: #000;
  background-color: #fff;
  margin-left: 30px;
}

.accordion {
  width: 200px;
  height: 0;
  transform-origin: top center;
  transform: scaleY(0);
  display: flex;
  flex-direction: column;
  z-index: 3333;
  transition: .2s;
  opacity: 0;
}

.active {
  transform: scaleY(1);
  height: auto;
  opacity: 1;
  transition: .1s;
}

.link {
  font-size: 16px;
  font-weight: 300;
  color: #222222;
  cursor: pointer;
  margin: 5px 0;
}
.exampleWrapper {
  width: 100%;
  height: 293px;
  background-color: #BDCCD440;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.plusItem {
  width: 221px;
  height: 235px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  &.chosen {
    border: 1px solid #FF7326
  }
}
.wrapperItem {
  width: 221px;
  height: 235px;
  padding: 18px;
  border: 1px solid #E8EDF0;
  background-color: #fff;
  cursor: pointer;
}
.img {
  width: 180px;
  height: 180px;
  object-fit: contain;
  margin: 0 auto
}
