.addNoteWrap {
}

.addNoteInput {
    background-color: #F5F7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    gap: 27px;
    margin-bottom: 18px;
}

.noteInput {
    outline: none;
    border: none;
    flex: 1;
    background-color: inherit;

    &::placeholder {
        color: #BFBFCB;
    }
}

.addNoteButton {
    border: none;
    &:hover {
        cursor: pointer;
        outline: 1px solid #343543;
    }
}