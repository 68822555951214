.wrapper {
  padding: 15px 30px;
  border: 1px solid #E8EDF0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  margin: 6px;
}

.title {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}
