@import 'Styles/variables';
@import '../../styles/variables';
.header {
  display: flex;
  align-items: center;
  &:global(.ant-layout-header) {
    padding: 0;
    border-bottom: @accountBorder;
  }
  .btn {

    &, & a {
      color: @orange;
    }
  }

}

.title {
  &:global(.ant-typography) {
    margin: 0 0 0 303px;
    border-left: @accountBorder;
    height: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.6px;
    font-weight: 700;
    font-size: 32px;
  }
}

.content{
  :global{
    .ant-layout-content{
      flex-grow: 1;
      // max-width: calc(100vw - 304px - 32px) !important;
      overflow: hidden  !important;
    }
  }
}
