.section {
  margin-bottom: 50px;
  position: relative;
}

.section_noMargin{
  display: none;
  margin-bottom: 0px;
  position: relative;
}

.companyWrapper{
  position: relative;
  background: #000 !important;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.title_none{
  display: none;
}

.text {
  margin-bottom: 20px;
  max-width: 720px;
  word-wrap: break-word;
}
.productItem {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.indicator {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.keyWord {
  margin-left: 5px;
}
.keyWord:after {
  content: ", ";
}
.keyWord:last-child:after {
  content: " ";
}
.more {
  color: #ea5b0c;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.imgWrapper {
  display: flex;
  flex-wrap: wrap;
}
.link {
  font-size: 16px;
  color: #EA5B0C;
  cursor: pointer;
}
.leftWrapper {
  width: 50%;
  border-right: 1px solid #E8EDF0;
}
.rightWrapper {
  width: 50%;
}
.formWrapper {
  display: flex;
  margin: 40px;
}
.rightWrapper:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: #E8EDF0;
}
.flex {
  display: flex;
}
.jcsb {
  justify-content: space-between;
}
.alignItemsCenter {
  align-items: center;
}
.social {
  padding: 24px 32px;
  background-color: #f5f7f8;
  max-width: 303px;
  text-align: center;
}
.iconWrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
}
.address {
  max-width: 303px;
  padding: 24px 32px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #343543;
}
.teg {
  margin-left: 5px;
}
//.additional:before {
//  content: '';
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 50%;
//  height: 100%;
//  width: 1px;
//  background-color: #E8EDF0;
//}
.additional1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}
