@import "Styles/variables";
.bell{
  cursor: pointer;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  rect{
    fill: transparent;
    transition: 0.5s;
  }
  &.opened{
    rect{
      fill: #F5F7F8;
    }
  }
  .unreadNotifications{
    position: absolute;
    z-index: 1;
    top: 0;
    left: calc(100% - 10px);
    background: #EA5B0C;
    padding: 2px 4px;
    border: 1px solid #EA5B0C;
    border-radius: 15px;
    color: #fff;
    font-size: 10px;
    line-height: 0.9;
    //display: flex;
    //align-items: center;
  }
}
.notificationsBlock{
  overflow: hidden;
  background: #FFF;
  z-index: 11;
  width: 360px;
  height: 100vh;
  position: fixed;
  top:0;
  right: -360px;
  bottom: 0;
  transition: 0.5s;
  border-left: 1px solid #E8EDF0;
  opacity: 0;
  visibility: hidden;
  padding: 24px 0px 24px 24px;
  .messagesBlock{
    padding-right: 24px;
    padding-bottom: 24px;
    overflow: auto;
    height: 85vh;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: @grey4;
    }
    &::-webkit-scrollbar-thumb {
      background: @grey2;
      border-radius: 8px;
    }
  }

  &.opened{
    display: block;
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .close{
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }
}
.notificationItem{
  border-bottom: 1px solid #E8EDF0;
  margin-bottom: 10px;
  .notRead{
    background: #F2F4F4;
    padding: 8px 8px 8px 27px;
    position: relative;
    z-index: 1;
    &::before{
      content: "";
      position: absolute;
      z-index: 1;
      display: block;
      height: 3px;
      width: 3px;
      border-radius: 15px;
      background: #222;
      top: 16px;
      left: 16px;
    }
  }
  .date{
    font-size: 10px;
    color: rgba(#343543, 0.35);
    padding: 20px 0;
  }
}
