@import "Styles/variables";

.wrapper {
  :global {
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #f5f7f8;
    }
    .ant-pagination ant-table-pagination ant-table-pagination-right {
      display: none;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
  background: #fff;
  max-width: 95%;
  width: 100%;
  padding: 32px;
  overflow-x: hidden !important;
  max-width: 1440px;

  @media (max-width: 1800px) {
    max-width: calc(100vw - 303px - 64px - 18px);
  }

  @media screen and (max-width: 1200px) {
    max-width: calc(100vw - 303px - 64px - 18px);
  }
}
