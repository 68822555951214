.categoriesSelect {
  display: flex;
  position: relative;
  width: 651px;
}

.wrapperCheckbox {
  :global {
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border: 1px solid #d9d9d9 !important;
      border-radius: 0px;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #ffffff !important;
      width: 5px;
      height: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ea5b0c !important;
    }
    .ant-checkbox-input[disabled] + .ant-checkbox-inner {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.dropdown {
  width: 450px;
}

.subcategories {
  position: absolute;
  right: 0;
  top: 35px;
}

.dropdownTitle {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}

.dropdownRender {
  display: flex;
  justify-content: space-between;
  padding: 20px 12px;
}

.selectedLenth {
  color: #d62d30;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.currentCategory {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 0.4px;
  cursor: none;
  pointer-events: none;
}

.square {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  margin-top: 2px;
  align-items: center;
  border: 1px solid #000000;
}

.labelContainer {
  width: 100%;
  display: flex;
  gap: 15px;
  padding: 5px 12px;
}

.plus {
  font-size: 16px;
  color: #000000;
}

.Cascader {
  position: relative;
  :global {
    .ant-select-selector {
      height: 40px !important;
    }
    .ant-select-selection-item {
      margin: auto;
    }
    .ant-select-arrow {
      right: 20px;
    }
    .ant-select-clear {
      display: none;
    }
  }
}

.currentLenthCategories {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.4px;
}

.subcategoryList {
  padding: 0;
}

.buttonDelete {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subcategoryItem {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;
  border: 1px solid #d9d9d9;
  background: #f5f7f8;
  padding: 4px 11px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subcategoryText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.currentSubcategories {
  font-weight: 600
}

.disabled {
  color: #b0aeb4;
}


.dropdownRenderContainer {
  :global {
    .ant-cascader-menu {
      width: 450px;
      height: 350px !important;
    }

    .ant-cascader-menus {
      height: 350px;
    }

    .ant-cascader-menu-item {
      flex-wrap: wrap;
      overflow: visible;
      line-height: 22px;
      white-space: wrap;
      text-overflow: none;
      cursor: pointer;
      transition: all 0.3s;
      margin: 0 7px;
      padding: 0;
    }


    .ant-cascader-menu-item-selected {
      background-color: #d62d30 !important;
    }

    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
      background-color: #e8edf0 !important;
    }

    .ant-cascader-menu-item-expand-icon {
      display: none;
    }

    .ant-cascader-menu:nth-child(2) .ant-cascader-menu-item:first-child {
      cursor: text;
      pointer-events: none;
      &:hover {
        background: inherit;
      }
    }

    .ant-cascader-menu:nth-child(2) .ant-cascader-menu-item {
      padding: 5px 12px;
    }

    .ant-cascader-menu:nth-child(2) {
      scroll-behavior: smooth;
      scroll-padding-top: 10px; /* Опционально, чтобы добавить отступ сверху */
    }

    .ant-cascader-menu::-webkit-scrollbar {
      width: 8px;
    }

    .ant-cascader-menu::-webkit-scrollbar-thumb {
      background: #e8edf0;
      border-radius: 5px;
      width: 5px;
    }
  }
}

.dropdownPosition {
  position: absolute;
  left: 336px !important;
}

.cross {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.horizontalLine,
.verticalLine {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: black;
}

.horizontalLine {
  width: 100%;
  height: 2px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.verticalLine {
  width: 2px;
  height: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.leftContent {
  display: flex;
  gap: 15px;
}

.customCheckbox {
  background-color: orange;
  border-radius: 0;
  width: 10px;
  height: 10px;
}
