.uploadButton {
  width: 498px;
  height: 85px;
  background-color: #f5f7f8;
  display: flex;
  align-items: center;
  justify-content: center;
  .uploadImage {
    margin-right: 44px;
  }
  .uploadTextWrap {
    margin-right: auto;
  }
  .uploadText {
    color: #478ecc;
    text-decoration: underline;
    font-weight: 300;
  }
  .uploadLoadText {
    font-size: 12px;
    font-weight: 300;
    color: #474747;
  }
  &:hover {
    cursor: pointer;
  }
}
