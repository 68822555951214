.processed {
  width: 126px;
  height: 36px;
  background: #dcfbea;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .tableRowDark {
    background: #dcfbea;
  }

  p {
    color: #249f5d;
  }
}

.wrapper {
  :global {
    .ant-table-wrapper {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-pagination {
            margin: 0;
            background-color: #fff;
            padding-bottom: 24px;
          }
        }
      }
    }
    .ant-table-thead .ant-table-cell {
      background: #f5f7f8;
    }
  }

  max-width: 100% !important;

  .section__table {
    max-width: 100%;
    width: calc(100%);
    overflow-x: auto;
    margin-bottom: 24px;

    :global {
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-thead > tr > .ant-table-cell {
        border-bottom: none;
        background: #f5f7f8;
        min-width: 158px !important;
        min-height: 64px !important;
        height: 64px !important;
      }

      .ant-table-thead > tr > .ant-table-cell {
        border-bottom: none;
        background: #f5f7f8;
        min-width: 160px !important;
        min-height: 64px !important;
        height: 64px !important;
      }

      .ant-table-tbody > .ant-table-row > .ant-table-cell {
        line-height: 16px !important;
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #fff !important;
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > .ant-table-row:last-child > td.ant-table-cell {
        border-bottom: none !important;
      }
    }
  }
}

.section__table {
  :global {
    .ant-table-thead .ant-table-cell {
      background: #f5f7f8;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
}

.not_processed {
  width: 126px;
  height: 36px;
  background: #fbdcdc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  p {
    color: #d62d30;
  }
}

.viewed {
  width: 126px;
  height: 36px;
  background: #fbe9dc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  p {
    color: #d67e2d;
  }
}

.not_processedRowTable {
  background-color: #fbdcdc;
}

.processedRowTable {
  background: #dcfbea;
}

.viewedRowTable {
  background: #fbe9dc;
}
