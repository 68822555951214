@import "../../styles/variables";

.header {
  background-color: #fff;
  //margin-bottom: 32px;
  z-index: inherit;
  box-shadow: 0px 1px 12px rgba(52, 53, 67, 0.21);
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
  }
  @media (max-width: 1347px) {
    h1 {
      font-size: 2.2rem;
    }
  }
  .authed {
    @media (min-width: 1038px) and (max-width: 1128px) {
      & > br {
        display: none;
      }
    }

    @media (min-width: 1430px) {
      & > br {
        display: none;
      }
    }

    @media (min-width: 1420px) and (max-width: 1513px) {
      & > br {
        display: block;
      }
    }

    @media (min-width: 1229px) and (max-width: 1278px) {
      & > br {
        display: none;
      }
    }
    @media (max-width: 1229px) {
      font-size: 1.5rem !important;
    }
    @media (max-width: 1038px) {
      & > br {
        display: none;
      }
    }
  }

  .not_authed {
    @media (min-width: 1390px) {
      & > br {
        display: none;
      }
    }
    @media (min-width: 1421px) and (max-width: 1512px) {
      & > br {
        display: block;
      }
    }
    @media (min-width: 1097px) and (max-width: 1226px) {
      & > br {
        display: none;
      }
    }
    @media (min-width: 1103px) and (max-width: 1178px) {
      & > br {
        display: block;
      }
    }
    @media (max-width: 1229px) {
      font-size: 1.5rem !important;
    }
    @media (max-width: 1081px) {
      & > br {
        display: none;
      }
    }
  }

  // @media (min-width: 1517px) {
  //   h1 {
  //     & > br {
  //       display: none;
  //     }
  //   }
  // }

  // @media (min-width: 1183) and (max-width: 1224px) {
  //   h1 {
  //     & > br {
  //       display: none;
  //     }
  //   }
  // }

  // @media (min-width: 1038px) and (max-width: 1109px) {
  //   h1 {
  //     & > br {
  //       display: none;
  //     }
  //   }
  // }

  .btn {
    //padding: 12px 20px;
    padding: 0 !important;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &,
    & a {
      font-size: 1.14rem;
      line-height: 1.2;
    }
  }

  .col {
    display: flex;
    align-items: center;
  }

  .top_row {
    border-bottom: 1px solid @grey2;
    height: 72px;
    flex-wrap: nowrap;

    .col {
      min-height: 100%;

      &:nth-child(2) {
        border-left: 1px solid @grey2;
        padding-left: 33px;
        //padding: 33px 12px 0px 12px ;
      }

      &.auth_col {
        padding-right: 24px;
        .btn {
          &,
          & a {
            color: @orange;
          }
        }
      }
    }
  }

  .bottom_row {
    padding: 0 30px;

    .col {
      padding: 12px 20px;
      justify-content: center;

      .btn {
        width: 100%;
        a {
          font-weight: 500;
          color: @blackBlue;
          white-space: break-spaces;
          &.active {
            color: @orange;
          }
        }

        &:hover {
          background-color: @blackBlue;

          a {
            color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1226px) {
    h1 {
      font-size: 1.2rem;
    }
    .bottom_row {
      .col {
        padding: 12px 10px;
      }
      a {
        padding: 12px 10px;
      }
    }
  }
}
