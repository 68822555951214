.wrapper {
  position: relative;
  top: -32px;
  left: -32px;
  width: calc(100% + 62px);
}

.sections {
  display: flex;
  gap: 32px;
  padding: 32px;
  width: 100%;

  @media only screen and (max-width: 1425px) {
    flex-direction: column;
  }
}

.section,
.sectionWindow {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 32px 32px 0px 32px;
  flex: 1;
}

.section {
  padding: 0;
}

.sectionWindow {
  max-height: 700px;
  padding: 32px 32px 30px;
}

.companyDetails {
  padding-bottom: 64px;
  // border-bottom: 3px solid black;
  margin: 32px 32px 0px 32px;
}

.companyDetails,
.companyNotes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.companyNotes {
  margin: 24px 32px 0px 32px;
}

.companyLogo {
  width: 105px;
  height: 80px;
  object-fit: contain;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail {
  display: flex;
  gap: 32px;
  word-break: break-word;
  p {
    min-width: 180px;
  }
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 400px;
}

.modalButtons {
  display: flex;
  gap: 90px;
}

.divider {
  width: 100%;
  background: #e8edf0;
  height: 1px;
  border: none;
  margin: 0;
}
