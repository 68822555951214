.section {
  max-width: 1900px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 32px 32px 30px;
}

.scrollable {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
