.text_block{
  font-size: 15px;
  padding-bottom: 18px;
  line-height: 1.5715;
  strong{
    font-weight: bold;
  }

  a{
    text-decoration: underline
  }

  li{
    margin-left: 15px;
    list-style: none;
    line-height: 1.5715;
    margin-bottom: 12px;
  }

  ul{
    li{
      list-style: initial;
      margin-bottom: 0px;
    }
  }
}

.title{
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 24px;
}

.bold{
  font-weight: bold;
}
