.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  background-color: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formTitle {
  font-size: 1.45rem;
}

.formClose {
  &:hover {
    cursor: pointer;
  }
}

.formCancel {
  padding: 8px 20px;
}

.formDisableSubmit {
  &:disabled {
    background-color: grey;
    &:hover {
      background-color: grey;
    }
  }
}

.formButtonsContainer {
  display: flex;
  gap: 24px;
  margin-top: 40px;
}
