@import "Styles/variables";
.msgBlock{
  font-size: 0.875rem;
  display: flex;
  margin-top: 12px;
  .message{
    background: #fff;
    padding: 18px;
    height: 100%;
    color: @general;
    word-break: break-all;
    max-width: 416px;
    position: relative;
    svg{
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &.read{
      svg{
        path{
          fill: @orange;
        }
      }
    }

  }

  .image {
    object-fit: contain;
    padding: 10px;

    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
  }

  .fileIcon {
    padding: 18px;
    height: 100%;
    color: @general;
    word-break: break-all;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    svg {
      position: static;
      width: 30px;
      height: 30px;
      color: @orange
    }
  }

  .dateTime{
    margin-left: 18px;
    color: @grey;
  }
  &.my{
    justify-content: flex-end;
    .message{
      background: @green2;
    }
    .fileIcon{
      background: @green2;
    }
    .image {
      background: @green2;
    }
  }
}

.fileMessage {
  &:hover {
    cursor: pointer;
  }
}