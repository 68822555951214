@import "Styles/variables";

.pageSizePickerLabel {
  font-weight: 300;
  font-size: 16px;
}

.pageSizePicker {
  :global(.ant-select-selection-item) {
    font-weight: 500;
  }
}
