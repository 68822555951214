@import 'Styles/variables';

.pagination {

  &:global(.ant-pagination) {
    display: flex;
    user-select: none;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
  }

  :global {
    .ant-pagination-prev {
      margin-right: 10px;
    }

    .ant-pagination-next {
      margin-left: 10px;
    }

    .ant-pagination-next:hover,
    .ant-pagination-prev:hover {
      a {
        color: @general;
      }
    }

    .ant-pagination-item,
    .ant-pagination-item:hover {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 44px;
      border: none;
      transition: all 0.1s;

      a,
      a:hover {
        color: @general;
      }
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:hover {
      font-weight: inherit;
      background: transparent;
      border-top: 2px solid @orange;

      a,
      a:hover {
        color: @blackBlue;
      }
    }
  }
}