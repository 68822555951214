.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto 100px;
  background-color: #fff;
  width: 100%;
}
.bef {
  &:before {
    left: 717px!important;
  }
}
.befRight {
  &:before {
    left: 70%!important;
  }
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: .3s;
}
