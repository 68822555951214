.wrapper {
  display: flex;
  padding: 32px;
}
.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.img {
  height: 260px;
  width: 260px;
  object-fit: contain;
  cursor: pointer;
  background-color: #fff;
}
.description {
  padding-left: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.name {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
}
.txt {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
}
.tegs {
  display: flex;
  max-width: 350px;
  flex-wrap: wrap;
  gap: 10px;
}
.teg {
  padding: 4px 12px;
  border: 1px solid #E8EDF0;
}
.socialWrapper {
  display: flex;
}
.social {
  margin-right: 10px;
}
.modal {
  :global(.ant-modal-body) {
    justify-content: center !important;
    width: max-content;
  }
  :global(.ant-modal-content) {
    width: max-content;
  }
  :global(.ant-modal) {
    display: flex!important;
    justify-content: center!important;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.btnStyle{
  position: relative;
  font-size: 16px!important;
  font-weight: 400!important;
  width: 400px!important;
  height: 44px!important;
  text-align: center;
  padding: 0 8px!important;

  &.hov{
    border: 1px solid #B0AEB4!important;
    padding-left: 30px!important;
    &:hover{
      svg{
        fill: #fff;
      }
    }
  }
}
.svg {
  position: absolute;
  left: 10px;
}
@media screen and (max-width: 1200px){
  .btnStyle, .btnStyle1{
    font-size: 16px!important;
    font-weight: 300!important;
    width: 400px!important;
  }
  .btnStyle1{
    font-size: 13px!important;
  }
}
