@import './variables';

.grey{
    color: @grey5;
}
.red{
    color: @red;
}
.orange{
    color: @orange;
}
.orange-link{
    color: @orange;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        color: rgb(64, 169, 255);
    }
}
.green{
    color: @green;
}
.darkRed{
    color: #B81C1C;
}
