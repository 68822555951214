.menu {
  width: 344px
}

.main {
  width: 100%;
}

.imgWrapper {
  border: 1px solid #E8EDF0;
  padding: 10px;
  width: 360px;
  position: relative;
  z-index: 2;
}

.image {
  width: 340px;
  height: 340px;
  object-fit: contain;
  cursor: pointer;
}

.smallImgWrapper {
  display: flex;
  width: 360px;
  margin-top: 18px;
  cursor: pointer;
  overflow: auto;
  position: relative;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.smallImage {
  min-width: 100px;
  height: 100px;
  max-height: 100px;
  margin-right: 30px;
  object-fit: contain;

  &:last-child {
    margin-right: 0;
  }
}

.arrowLeft {
  position: absolute;
  left: -25px;
  top: 35px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: #40a9ff;
  }
}

.arrowRight {
  position: absolute;
  right: -25px;
  top: 35px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: #40a9ff;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  user-select: none;
}

.subTitle {
  color: #9193AA;
  font-size: 14px;
  font-weight: 400;
  margin-top: 18px;
  user-select: none;
}

.subValue {
  color: #343543;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
}

.advantage {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  position: relative;
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.wrapper {
  width: 338px;
  position: absolute;
  right: -67px;

  &:before {
    display: none;
  }
}

.left {
  width: 70%;
}

.form {
  margin-top: 82px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 270px;
    background-color: #E8EDF0;
  }
}

.link a {
  color: #fff !important;
}

.address {
  max-width: 303px;
  padding: 24px 32px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #343543;
}

.paymentMethod {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 170px;
}

.modal {
  :global(.ant-modal-content) {
    width: 100% !important;
    height: 100% !important;
    background-color: inherit !important;
    box-shadow: none !important;
  }

  &:global(.ant-modal) {
    width: 80% !important;
    height: 80% !important;
    user-select: none;
  }

  :global(.ant-modal-body) {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    height: 100% !important;
  }

  .prev, .next {
    min-width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    &:hover {
      color: #fff;
    }
  }

  .modalWrapper {
    display: flex;
    justify-content: space-between;
  }
  .imgModal {
    max-width: calc(100% - 200px);
    object-fit: contain;
  }
  .arrow {
    font-size: 40px;
  }
}
