.ml-18 {
  margin-left: 18px;
}
.mt-50 {
  margin-top: 50px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-15 {
  margin-left: 15px;
}

.ml-auto {
  margin-left: auto;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-35 {
  margin-right: 35px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-12 {
  margin-top: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-95 {
  margin-bottom: 95px;
}
.p-0 {
  padding: 0 !important;
}
.pl-12 {
  padding-left: 12px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-18 {
  padding-top: 18px;
}
.pr-5 {
  padding-right: 5px;
}
