.link {
  color: #ea5b0c;
  font-size: 14px;
  margin: 6px 0;
  cursor: pointer;
  font-weight: 400;
  transition: .2s;
  .active{
    color: #000 !important;
  }
}
.bold{
  font-weight: 700;
}
.link:hover {
  color: #000 !important;
}
