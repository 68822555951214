@import './variables.less';

ul {
    padding-left: 20px;
    margin-bottom: 0;
}

.list_num {
    font-weight: 900;
    color: #fff;
    font-size: 1.4rem;
    background-color: @blackBlue;
    margin-right: 18px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 100%;
}