@import "./variables.less";

.regular_block {
  background-color: #fff;
  padding: 32px 32px 25px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  &.centered {
    align-items: center;
  }

  .heading {
    font-size: 1.14rem;
    font-weight: 300;
    line-height: 1.3;
    width: 70%;
    margin-bottom: 32px;
  }

  .label {
    line-height: 1.2;
    font-weight: 500;

    &.req::after {
      content: "*";
    }
  }

  input,
  textarea,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper {
    background: @grey4;

    background-color: @grey4 !important;

    &:hover,
    &:focus {
      border-color: @grey;
      box-shadow: none;
    }
  }

  .radio_group {
    display: flex;
    justify-content: space-between;

    .radio_btn {
      position: relative;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        background-color: @grey4;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: 12px;
      }

      &::after {
        content: "";
        width: 14px;
        height: 14px;
        background-color: @blackBlue;
        border-radius: 50%;
        position: absolute;
        top: 17px;
        left: 17px;
        transition: 0.3s;
        transform: scale(0);
      }

      &.active::after {
        transform: scale(1);
      }

      &:first-child {
        border-right: 1px solid @grey2;
      }

      box-shadow: none;
      border: 1px solid @grey2;
      padding: 12px 12px 20px 48px;
      height: auto;
      display: flex;
      flex-direction: column;
      width: calc((100% / 2.9) - 24px);

      span[ant-radio-button]:focus {
        box-shadow: none;
      }

      .title {
        display: block;
        font-size: 1.14rem;
        font-weight: 600;
        line-height: 1.2;
        color: @general;
      }

      .subtitle {
        display: block;
        // font-size: 1.14rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        color: @grey3;
      }
    }
  }

  .captcha {
    border: 1px solid #e8edf0;
    display: flex;
    span{
      display: block;
      margin: 0 15px
    }
    div {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .submit, .submit[disabled] {
    background: #ea5b0c;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
    }
  }
  .imgWrapper {
    display: flex;
    align-items: center;
    background-color: #f5f7f8;
    color: #478ecc;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
    svg {
      flex-basis: 99px;
      flex-shrink: 0;
    }
    label {
      //max-width: 100%;
      //flex-shrink: 1;
      flex: 0 1 auto;
    }
    .trash {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .link-heading {
      font-weight: 500;
      color: #222;
      margin-left: 30px;
    }
    .link {
      margin-left: 30px;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;
    }
    .file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
}
