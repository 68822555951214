.wrapper {
  display: flex;
  padding: 18px 32px;
  align-items: center;
}
.txt {
  font-size: 16px;
  font-weight: 400;
  min-width: 390px;
}
.inputWrapper {
  position: relative;
  width: 100%;
  padding-right: 32px;
}

.input_block{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.input {
  outline: none;
  border: none;
  background-color: #F5F7F8;
  color: #000;
  padding: 14.5px 20px;

  width: 100%;
  font-size: 16px;
}
.input::placeholder {
  color: #b0aeb4;
}
.searchField {
  position: absolute;
  left: 32px;
  background-color: #fff;
  border: 2px solid #F5F7F8;
  border-top: none;
  width: 100%;
  padding: 18px;
  display: flex;
  height: 0;
  transform-origin: top center;
  transform: scaleY(0);
  z-index: 3333;
}
.searchFieldActive {
  height: auto;
  transform: scaleY(1);
}

.img {
  width: 32px;
  height: 32px;
  background-color: grey;
}

