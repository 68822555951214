.wrapper {
  padding: 18px;
  border: 1px solid #E8EDF0;
  margin: 10px;
  position: relative;
  cursor: pointer;
  &.chosen{
    border-color: #EA5B0C;
  }
  &.chosenFs9{
    border-color: #FF7326;
  }
}
.wrapperAndHov {
  padding: 18px;
  border: 1px solid #E8EDF0;
  margin: 10px;
  transition: .3s;
  &:hover {
    color: #40a9ff;
  }
}
.img {
  width: 220px;
  height: 220px;
  object-fit: contain;
  margin: 0 auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 220px;
}
.title {
  max-width: 50%;
  font-weight: 500;
  word-wrap: break-word;
}
input[checked] {
  width: 24px;
  height: 24px;
}
.visible {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
  padding: 0 5px;
  color: #fff;
  font-weight: 300;
}
