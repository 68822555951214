.wrapper {
  background: #F5F7F8;
  width: 223px;
  padding: 12px 3px;
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-direction: column;
}
.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.wrapper_none{
  display: none;
}
