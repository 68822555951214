.fz-12 {
  font-size: 0.85rem;
}
.fz-18 {
  font-size: 1.285rem;
}
.fz-17 {
  font-size: 1.182rem;
}
.fz-11 {
  font-size: 0.6875rem;
}
