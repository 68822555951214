.wrapper {
  position: relative;
  width: 1300px;
  background-color: #fff;
  padding: 32px;
  @media screen and (max-width: 1660px){
    width: 100%;
  }
}

.wrapper__close{
  position: absolute;
  top: 32px;
  right: 32px;
}
